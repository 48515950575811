import React, { Component, Fragment } from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
// import 'typeface-roboto';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import Auth from './Auth';
import { SignIn, ResetPassword, ChangePassword, Profile } from './views/auth';
import { OrderList, OrderForm, Privacy, PrivacyWithHeader } from './views';
import AppBar from './components/AppBar';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#009fe3',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#a71680'
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif'
    ].join(',')
  },
});
theme = responsiveFontSizes(theme);

class App extends Component {
  state = {
    ready: false,
    isAuthenticated: false,
  };
  listener = null;

  componentWillMount() {
    this.listener = Auth
      .bind(user=>{
        this.setState({ isAuthenticated: !!user, ready: true });
      });

    Auth
      .login()
      .catch(()=>{
        this.setState({ ready: true });
      });
  }

  componentWillUnmount() {
    if (typeof this.listener === 'function') {
      this.listener();
    }
  }

  getContent = ()=>{
    const { ready, isAuthenticated } = this.state;

    if (!ready) {
      return null;
    }

    if (!isAuthenticated) {
      return (
        <Switch>
          <Route path="/esqueci_senha" component={ResetPassword} />
          <Route path="/privacidade" component={PrivacyWithHeader} />
          <Route path="/alterar_senha" component={ChangePassword} />
          <Route component={SignIn} />
        </Switch>
      );
    }

    return (
      <Fragment>
        <AppBar />
        <Switch>
          <Route path="/calculos" component={OrderList} />
          <Route path="/perfil" component={Profile} />
          <Route path="/privacidade" component={Privacy} />
          <Route path="/calculo/:orderId" component={OrderForm} />
          <Route component={OrderForm} />
        </Switch>
      </Fragment>
    );
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <CssBaseline />
          <Router>
            {this.getContent()}
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}

export default App;
