import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Auth from '../../Auth';

import withLayout from './Layout';

class ChangePassword extends PureComponent {
  constructor(props) {
    super(props);

    const { location, history } = props;

    const param = new URLSearchParams(location.search);
    const token = param.get('token');

    if (!token) {
      history.replace('/');
    }

    this.state = {
      loading: false,
      password: '',
      passwordConfirmation: '',
      token
    };
  }

  handleSubmit = event=>{
    event.preventDefault();
    const { password, passwordConfirmation, token } = this.state;
    const { enqueueSnackbar, history } = this.props;

    if (password !== passwordConfirmation) {
      return enqueueSnackbar('As senha não coincidem', { variant: 'error' });
    }

    this.setState({loading: true}, ()=>{
      Auth
        .changePassword(password, token)
        .then(()=>{
          enqueueSnackbar('Senha alterada com sucesso', { variant: 'success' });
          history.replace('/');
        })
        .catch(xhr=>{
          enqueueSnackbar(xhr.message || 'Falha ao alterar a senha', { variant: 'error' });
          this.setState({loading: false});
        });
    });
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { classes, variant, margin } = this.props;
    const { password, passwordConfirmation, loading } = this.state;

    return (
      <form className={classes.form} onSubmit={this.handleSubmit} noValidate>
        <TextField
          variant={variant}
          margin={margin}
          required
          fullWidth
          value={password}
          onChange={this.handleChange}
          type="password"
          label="Senha"
          name="password"
          autoFocus
        />
        <TextField
          variant={variant}
          margin={margin}
          required
          fullWidth
          value={passwordConfirmation}
          onChange={this.handleChange}
          type="password"
          label="Confirmar senha"
          name="passwordConfirmation"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading||!password||!passwordConfirmation}
        >
          Alterar
        </Button>
      </form>
    );
  }
};

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.string.isRequired,
  margin: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withLayout(ChangePassword, { title: 'Alterar senha' });