import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { withSnackbar } from 'notistack';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import { asyncRequest } from '../../Auth';

const styles = theme=>({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& + &': {
      marginTop: theme.spacing(3),
    }
  },
  buttons: {
    marginTop: theme.spacing(4),
  },
});

class Profile extends Component {
  state = {
    email: '',
    password: '',
    currentPassword: '',
    passwordConfirmation: '',
    firstName: '',
    lastName: '',
    loading: true,
  };

  componentWillMount() {
    this.fetchData();
  }

  fetchData = ()=>{
    asyncRequest('/users/me')
      .then(user=>{
        this.setState({
          loading: false,
          email: user.email,
          firstName: user.Profile.firstName,
          lastName: user.Profile.lastName,
        });
      })
      .catch(xhr=>{
        this.props.enqueueSnackbar(xhr.message, { variant: 'error' });
      });
  };

  updateUser = event=>{
    event.preventDefault();
    const { enqueueSnackbar } = this.props;
    const { email, firstName, lastName } = this.state;
    this.changeUserData({ email, firstName, lastName })
      .then(user=>{
        this.setState({loading: false});
        enqueueSnackbar('Dados atualizados com sucesso', { variant: 'success' });
      });
  };

  updatePassword = event=>{
    event.preventDefault();
    const { enqueueSnackbar } = this.props;
    const { password, passwordConfirmation, currentPassword } = this.state;
    if (passwordConfirmation !== password) {
      return enqueueSnackbar(`Os campos de senha e confirmação de senha não conferem`);
    }
    this.changeUserData({ password, currentPassword })
      .then(user=>{
        this.setState({
          loading: false,
          password: '',
          currentPassword: '',
          passwordConfirmation: '',
        });
        enqueueSnackbar('Senha atualizada com sucesso', { variant: 'success' });
      });
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  changeUserData = body=>{
    const { enqueueSnackbar } = this.props;

    return new Promise((resolve, reject)=>{
      this.setState({loading: true}, ()=>{
        asyncRequest('/users/me', {
          method: 'PUT',
          body
        })
          .then(resolve)
          .catch(xhr=>{
            this.setState({ loading: false });
            enqueueSnackbar(xhr.message, { variant: 'error' });
            reject(xhr);
          });
      });
    });
  };

  render() {
    const { classes } = this.props;
    const { email, firstName, lastName, password, currentPassword, passwordConfirmation, loading } = this.state;

    const invalidPasswordConfirmation = !!password&&password!==passwordConfirmation;

    return (
      <Container className={classes.root}>
        <Paper className={classes.paper}>
          <form
            autoComplete="off"
            onSubmit={this.updateUser}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="E-mail"
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  disabled={loading}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Nome"
                  name="firstName"
                  value={firstName}
                  onChange={this.handleChange}
                  disabled={loading}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Sobrenome"
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChange}
                  disabled={loading}
                  fullWidth
                />
              </Grid>
            </Grid>

            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={loading||!email||!firstName||!lastName}
              >
                Atualizar perfil
              </Button>
            </div>
          </form>
        </Paper>

        <Paper className={classes.paper}>
          <form
            autoComplete="off"
            onSubmit={this.updatePassword}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Senha atual"
                  type="password"
                  name="currentPassword"
                  value={currentPassword}
                  onChange={this.handleChange}
                  disabled={loading}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Nova senha"
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                  disabled={loading}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label="Confirmar nova senha"
                  type="password"
                  name="passwordConfirmation"
                  value={passwordConfirmation}
                  onChange={this.handleChange}
                  disabled={loading}
                  error={invalidPasswordConfirmation}
                  fullWidth
                />
              </Grid>
            </Grid>

            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={loading||invalidPasswordConfirmation||!currentPassword||!password||!passwordConfirmation}
              >
                Alterar senha
              </Button>
            </div>
          </form>
        </Paper>
      </Container>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(withStyles(styles)(Profile));