import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';

import { Link as NavLink } from 'react-router-dom';

import Auth from '../../Auth';

import withLayout from './Layout';

class ResetPassword extends PureComponent {
  state = {
    loading: false,
    email: '',
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { email } = this.state;
    const { enqueueSnackbar, closeSnackbar } = this.props;

    const sendingSnackbarId = enqueueSnackbar('Aguarde, enviando e-mail...', { variant: 'info', persist: true });
    this.setState({loading: true}, ()=>{
      Auth
        .resetPassword(email)
        .then(()=>{
	  closeSnackbar(sendingSnackbarId);
          enqueueSnackbar('E-mail enviado com sucesso', { variant: 'success' });
        })
        .catch(xhr=>{
          enqueueSnackbar(xhr.message || 'Falha ao enviar e-mail', { variant: 'error' });
          this.setState({loading: false});
        });
    });
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { classes, variant, margin } = this.props;
    const { email, loading } = this.state;

    return (
      <form className={classes.form} onSubmit={this.handleSubmit} noValidate>
        <TextField
          variant={variant}
          margin={margin}
          required
          fullWidth
          value={email}
          onChange={this.handleChange}
          id="email"
          label="E-mail"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading||!email}
        >
          Enviar e-mail
        </Button>
        <Link component={NavLink} to="/" variant="body2">
          Voltar para o login
        </Link>
      </form>
    );
  }
};

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.string.isRequired,
  margin: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withLayout(ResetPassword, { title: 'Resetar senha' });
