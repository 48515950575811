import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';

import { Link as NavLink } from 'react-router-dom';

import Auth from '../../Auth';

import withLayout from './Layout';

class SignIn extends PureComponent {
  state = {
    loading: false,
    email: '',
    password: '',
    remember: true
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { email, password, remember } = this.state;

    this.setState({loading: true}, ()=>{
      Auth
        .auth({ email, password }, remember)
        .catch(xhr=>{
          const message = xhr.message || 'Falha no login';
          this.props.enqueueSnackbar(message, { variant: 'error' });
          this.setState({loading: false});
        });
    });
  };

  handleChange = event=>{
    const { name, value, checked, type } = event.target;
    this.setState({
      [name]: type === 'checkbox' ? checked : value
    });
  };

  render() {
    const { classes, variant, margin } = this.props;
    const { email, password, remember, loading } = this.state;

    return (
      <form className={classes.form} onSubmit={this.handleSubmit} noValidate>
        <TextField
          variant={variant}
          margin={margin}
          required
          fullWidth
          value={email}
          onChange={this.handleChange}
          id="email"
          label="E-mail"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          variant={variant}
          margin={margin}
          required
          fullWidth
          value={password}
          onChange={this.handleChange}
          name="password"
          label="Senha"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <FormControlLabel
          control={<Checkbox
            name="remember"
            color="primary"
            checked={remember}
            onChange={this.handleChange}
          />}
          label="Manter conectado"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading||!email||!password}
        >
          Entrar
        </Button>
        <Link component={NavLink} to="/esqueci_senha" variant="body2">
          Esqueceu a senha?
        </Link>
      </form>
    );
  }
};

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.string.isRequired,
  margin: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withLayout(SignIn, { title: 'Login' });