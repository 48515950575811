import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withSnackbar } from 'notistack';

// import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const styles = theme=>({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: 60,
    height: 60,
    backgroundColor: theme.palette.secondary.main,
  },
  brand: {
    marginBottom: theme.spacing(4),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

export default function withLayout(WrappedComponent, { title } = {}) {
  return withSnackbar(withStyles(styles)(({ classes, ...other })=>(
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        {/*<Avatar className={classes.avatar}>
          <LockOutlinedIcon fontSize="large" />
        </Avatar>*/}
        <div className={classes.brand}>
          <img src="/assets/knauf_logo@2x.png" height="80" alt="Knauf Isopor" />
        </div>
        {!!title&&(
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
        )}
        <WrappedComponent
          {...other}
          classes={classes}
          margin="normal"
          variant="outlined"
        />
      </div>
    </Container>
  )));
};