import React from 'react';
import { get } from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';

import Chip from '@material-ui/core/Chip';

import green from '@material-ui/core/colors/green';
import indigo from '@material-ui/core/colors/indigo';

const styles = theme=>({
  created: {
    color: theme.palette.grey[500],
  },
  updated: {
    color: theme.palette.grey[500],
  },
  finished: {
    color: indigo[500],
  },
  confirmed: {
    color: green[600],
  },
});

export const OrderStatuses = {
  created: {
    label: "Em aberto",
  },
  updated: {
    label: "Em aberto",
  },
  finished: {
    label: "Aguardando confirmação",
    color: "secondary",
  },
  confirmed: {
    label: "Pedido realizado",
    color: "secondary",
    variant: "outlined",
  },
  refused: {
    label: "Recusado",
    variant: "outlined",
  },
  canceled: {
    label: "Cancelado",
    variant: "outlined",
  },
  ERR: {
    label: '',
  }
}

const OrderStatus = ({ item, classes })=>{
  const status = get(item, 'OrderStatus.status');
  const { Icon, label, ...props } = OrderStatuses[status]||OrderStatuses['ERR'];
  return (
    <Chip
      {...props}
      label={String(label).toUpperCase()}
    />
  );
};

export default withStyles(styles)(OrderStatus);