import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { withSnackbar } from 'notistack';
import { map, without } from 'lodash';

import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { Link } from 'react-router-dom';

import Auth, { asyncRequest } from '../Auth';

import OrderItem from '../components/OrderItem';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(4),
  },
  button: {
    marginRight: theme.spacing(2),
  },
});

class OrderList extends Component {
  state = {
    orders: null,
    reasons: null,
    count: null,
    loading: false
  };
  perPage = 12;
  page = 0;
  removed = 0;

  componentWillMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({loading: true}, () => {
      asyncRequest('/orders', {
        query: {
          order: [['id', 'DESC']],
          limit: this.perPage,
          offset: this.perPage*this.page-this.removed,
          where: {
            userId: Auth.currentUser.id
          },
          include: [{
            model: 'Category',
            required: true
          }, {
            model: 'Factory',
            required: true
          }, {
            model: 'Product',
            required: true,
            include: {
              model: 'ProductCategory'
            }
          }, {
            model: 'Market',
            required: true
          }, {
            model: 'FactoryBilling',
            required: true,
            include: {
              model: 'Billing',
              required: true
            }
          }, {
            model: 'Shipping'
          }, {
            model: 'OrderView',
            include: {
              model: 'View'
            }
          }, {
            model: 'OrderInput',
            include: {
              model: 'Input'
            }
          }, {
            model: 'OrderStatus',
            where: {
              status: { notIn: ['canceled'] }
            },
            required: true
          }]
        }
      })
        .then(response => {
          this.removed = 0;
          this.page++;
          this.setState(({ orders }) => ({
            orders: orders ? orders.concat(response) : response,
            count: response.count,
            loading: false
          }));
        })
        .catch(xhr => {
          this.setState({loading: false});
          this.props.enqueueSnackbar(xhr.message, {variant: 'error'});
        });

      asyncRequest('/reasons')
        .then(reasons => {
          this.setState({ reasons });
        });
    });
  };

  onRemove = order => {
    this.removed++;
    this.setState(({ orders }) => ({
      orders: without(orders, order)
    }));
  };

  render() {
    const { classes } = this.props;
    const { orders, reasons, count, loading } = this.state;

    return (
      <Container className={classes.root}>
        {map(orders, order => (
          <OrderItem
            key={order.id}
            data={order}
            onRemove={this.onRemove}
            reasons={reasons}
          />
        ))}

        {!!orders&&!orders.length&&(
          <Paper className={classes.paper}>
            <Typography variant="body1">
              Nenhum cálculo encontrado
            </Typography>
          </Paper>
        )}

        <div className={classes.buttons}>
          {!!orders&&orders.length<count&&(
            <Button
              variant="contained"
              size="large"
              color="secondary"
              disabled={loading}
              onClick={this.fetchData}
              className={classes.button}
            >
              Carregar mais cálculos
            </Button>
          )}
          <Button
            component={Link}
            to="/"
            variant="contained"
            color="primary"
            size="large"
          >
            Novo cálculo
          </Button>
        </div>
      </Container>
    );
  }
}

OrderList.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(withStyles(styles)(OrderList));