import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { withSnackbar } from 'notistack';
import { map, get, sortBy, find, filter, transform, groupBy, reject } from 'lodash';
import moment from 'moment';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import red from '@material-ui/core/colors/red';

import { Link } from 'react-router-dom';

import { asyncRequest } from '../Auth';
import { priceFormat } from '../helpers';

import OrderStatus from '../components/OrderStatus';

const styles = theme => ({
  red: {
    color: red[600],
    '&:hover': {
      backgroundColor: red[50],
    },
  },
  textMuted: {
    // color: theme.palette.text.secondary,
  },
  viewRow: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    }
  },
  grow: {
    flex: 1,
  },
  fillGrow: {
    [theme.breakpoints.up('md')]: {
      flex: 1,
    }
  },
  status: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing()
    }
  },
  center: {
    alignItems: 'center'
  },
});

const checkStatus = (order, check = ['created', 'updated']) => {
  const status = get(order, 'OrderStatus.status');
  return Array.isArray(check) ? check.includes(status) : check === status;
};

class OrderItem extends Component {
  state = {
    open: false,
    refusing: null,
    loading: false
  };

  handleRemove = async () => {
    const { onRemove, data } = this.props;

    await asyncRequest(`/orders/${data.id}/cancel`, { method: 'DELETE' });
    onRemove(data);
  };

  openRefuse = event => {
    this.setState({ refusing: event.currentTarget });
  };

  closeRefuse = () => {
    this.setState({ refusing: null });
  };

  handleRefuse = reasonId => event => {
    const { onRemove, data } = this.props;

    this.setState({ refusing: null, loading: true }, () => {
      asyncRequest(`/orders/${data.id}/refused`, {
        method: 'POST',
        body: { reasonId }
      })
        .then(count => onRemove(data))
        .catch(xhr => this.setState({ loading: false }));
    });
  };

  handleConfirm = event => {
    const { enqueueSnackbar, data } = this.props;

    this.setState({loading: true}, () => {
      asyncRequest(`/orders/${data.id}/confirm`, {
        method: 'POST'
      })
        .then(orderStatus => {
          data.orderStatusId = orderStatus.id;
          data.OrderStatus = orderStatus;
          enqueueSnackbar('Pedido solicitado com sucesso', { variant: 'success' });
          this.forceUpdate();
        })
        .catch(xhr => {
          enqueueSnackbar(xhr.message, { variant: 'error' });
          this.setState({loading: false});
        });
    });
  };

  toggleOpen = event => {
    this.setState(({ open }) => ({ open: !open }));
  };

  getActions = () => {
    const { classes, data: order, reasons } = this.props;
    const { refusing, loading } = this.state;

    if (checkStatus(order, ['confirmed', 'refused'])) {
      return null;
    }

    if (checkStatus(order, 'finished')) {
      return (
        <ExpansionPanelActions>
          <Button
            size="small"
            aria-owns={refusing ? 'refuse-menu' : undefined}
            aria-haspopup="true"
            onClick={this.openRefuse}
            className={classes.red}
            disabled={loading}
          >
            Recusar pedido
          </Button>
          <Menu
            id="refuse-menu"
            anchorEl={refusing}
            open={Boolean(refusing)}
            onClose={this.closeRefuse}
          >
            {map(sortBy(reasons, 'position'), ({ id, name })=>(
              <MenuItem key={id} onClick={this.handleRefuse(id)}>{name}</MenuItem>
            ))}
          </Menu>

          <Button
            size="small"
            onClick={this.handleConfirm}
            color="secondary"
            disabled={loading}
          >
            Solicitar pedido
          </Button>
        </ExpansionPanelActions>
      );
    }

    return (
      <ExpansionPanelActions>
        <Button
          size="small"
          component={Link}
          to={`calculo/${order.id}`}
          color="primary"
        >
          Editar pedido
        </Button>
      </ExpansionPanelActions>
    );
  };

  render() {
    const { classes, data: order } = this.props;
    const { open } = this.state;

    const inputs = groupBy(sortBy(filter(get(order, 'OrderInputs'), 'Input.group'), 'inputId'), 'Input.group');
    const inputValues = transform(inputs, (result, arr) => {
      result.push(map(arr, ({ value, Input }, i) => {
        const unit = get(Input, 'unit');
        const prevUnit = get(arr, `[${i+1}].Input.unit`);
        if (unit !== prevUnit) {
          return `${value}${unit}`;
        }
        return value;
      }).join('x'));
    }, []).join(' ');
    const inputExtra = filter(reject(get(order, 'OrderInputs'), 'Input.group'), 'value');
    const client = get(order, 'client');
    const application = get(order, 'application');

    const productCategory = find(get(order, 'Product.ProductCategories'), { categoryId: order.categoryId });
    const productName = get(productCategory, 'name') || get(order, 'Product.name');

    const createdAt = get(order, 'createdAt');

    return (
      <ExpansionPanel expanded={open} onChange={this.toggleOpen}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid className={classes.center} container>
            <Grid item xs={12} sm={4} md={2}>
              <Typography>
                {get(order, 'code')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md="auto">
              <Typography className={classes.textMuted}>
                <strong>{productName} {inputValues}</strong>
              </Typography>
            </Grid>
            <Grid item className={classes.grow} />
            <Grid className={classes.status} item>
              <OrderStatus item={order} />
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={3}>
            {createdAt&&(
              <Grid item>
                <Typography variant="overline" className={classes.textMuted}>Criado em</Typography>
                <Typography>{moment(createdAt).format('DD/MM/YYYY')}</Typography>
              </Grid>
            )}

            <Grid item>
              <Typography variant="overline" className={classes.textMuted}>Categoria</Typography>
              <Typography>{get(order, 'Category.name')}</Typography>
            </Grid>

            <Grid item>
              <Typography variant="overline" className={classes.textMuted}>Produção</Typography>
              <Typography>{get(order, 'Factory.name')}</Typography>
            </Grid>

            <Grid item>
              <Typography variant="overline" className={classes.textMuted}>Entrega</Typography>
              <Typography>{get(order, 'FactoryBilling.Billing.name')} - {get(order, 'Shipping.name') || 'FOB'}</Typography>
            </Grid>

            <Grid item>
              <Typography variant="overline" className={classes.textMuted}>Mercado</Typography>
              <Typography>{get(order, 'Market.name')}</Typography>
            </Grid>

            {(!!client||!!application)&&(
              <Grid item>
                <Typography variant="overline">Cliente</Typography>
                <Typography>{client} {application&&`(${application})`}</Typography>
              </Grid>
            )}

            {map(inputExtra, input => (
              <Grid key={input.id} item>
                <Typography variant="overline">{get(input, 'Input.name')}</Typography>
                <Typography>{input.value} {get(input, 'Input.unit')}</Typography>
              </Grid>
            ))}

            <div className={classes.fillGrow} />

            {map(get(order, 'OrderViews'), ov => (
              <Grid className={classes.viewRow} item key={ov.id}>
                <Typography variant="overline" className={classes.textMuted}>{get(ov, 'View.name')}</Typography>
                <Typography><strong>{priceFormat(get(ov, 'value'))}</strong></Typography>
              </Grid>
            ))}
          </Grid>
        </ExpansionPanelDetails>
        <Divider />        
        {this.getActions()}
      </ExpansionPanel>
    );
  }
}

OrderItem.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  reasons: PropTypes.array,
};

export default withSnackbar(withStyles(styles)(OrderItem));