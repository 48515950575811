import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { withSnackbar } from 'notistack';
import { map, orderBy, get, find, flatMap, transform, filter, pick, each, mapValues, sortBy } from 'lodash';
import { diff } from 'deep-object-diff';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';

import red from '@material-ui/core/colors/red';

import { Link } from 'react-router-dom';

import { asyncRequest } from '../Auth';
import { priceFormat } from '../helpers';

const styles = theme=>({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3)
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  result: {
    marginRight: theme.spacing(3)
  },
  buttons: {
    marginTop: theme.spacing(3)
  },
  button: {
    marginLeft: theme.spacing(4)
  },
  danger: {
    marginLeft: theme.spacing(4),
    color: theme.palette.getContrastText(red[700]),
    backgroundColor: red[700],
    '&:hover': {
      backgroundColor: red[900],
    }
  },
});

const ORDER = [
  'categoryId',
  'productId',
  'factoryId',
  'factoryBillingId',
  'shippingId',
  'marketId',
  'client',
  'application'
];

const getInputName = inputId=>`input_${inputId}`;

class OrderForm extends Component {
  state = {
    categoryId: '',
    productId: '',
    factoryId: '',
    factoryBillingId: '',
    shippingId: '',
    marketId: '',
    client: '',
    application: '',
    categories: null,
    products: null,
    factories: null,
    markets: null,
    order: null,
    loading: false,
  };
  inputs = {};

  componentWillMount() {
    asyncRequest('/categories', {
      query: {
        order: [['name', 'ASC']],
        include: {
          model: 'Input',
          include: 'Letter'
        }
      }
    })
      .then(categories=>{
        this.setState({ categories });
      });

    asyncRequest('/factories', {
      query: {
        order: [['name', 'ASC']],
        include: [{
          model: 'FactoryBilling',
          required: true,
          include: {
            model: 'Billing',
            required: true,
          }
        }, {
          model: 'Shipping'
        }]
      }
    })
      .then(factories=>{
        this.setState({ factories });
      });
    asyncRequest('/markets', {
      query: {
        order: [['name', 'ASC']],
        include: {
          model: 'MarketTax'
        }
      }
    })
      .then(markets=>{
        this.setState({ markets });
      });
  }

  componentDidMount() {
    this.fetchData(this.props.match.params.orderId);
  }

  componentWillReceiveProps(props) {
    const orderId = props.match.params.orderId;
    if (orderId !== this.props.match.params.orderId) {
      this.fetchData(orderId);
    }
  }

  fetchData = orderId=>{
    if (orderId) {
      this.setState({loading: true}, ()=>{
        asyncRequest(`/orders/${orderId}`, {
          query: {
            include: [{
              model: 'OrderView',
              include: 'View'
            }, {
              model: 'OrderInput'
            }]
          }
        })
          .then(order=>{
            this.inputs = {};
            each(order.OrderInputs, oi=>{
              const original = oi.value;
              this.inputs[getInputName(oi.inputId)] = {
                id: oi.inputId,
                value: oi.value,
                original
              };
            });
            this.setState({
              loading: false,
              order,
              ...pick(order, ORDER),
              ...mapValues(this.inputs, i=>i.value),
            }, this.handleChangeCategory);
          });
      });
    } else {
      this.setState({
        order: null,
        products: null,
        ...transform(ORDER, (r, v)=>{
          r[v] = '';
        }, {})
      })
    }
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.updateData({ [name]: (name !== 'shippingId' || value) ? value : null });
  };

  handleChangeTab = name=>(event, value)=>{
    this.updateData({ [name]: value });
  };

  handleChangeInput = inputId=>event=>{
    const { value } = event.target;
    const name = getInputName(inputId);
    if (!this.inputs[name]) {
      this.inputs[name] = {
        id: inputId,
        original: value
      };
    }
    this.inputs[name].value = value;
    this.handleChange(event);
  };

  handleChangeCategory = ()=>{
    const { categoryId } = this.state;

    asyncRequest('/products', {
      query: {
        where: {
          active: true
        },
        order: [['name', 'ASC']],
        include: [
          {
            model: 'ProductCategory',
            required: true,
            where: {
              categoryId
            }
          }, {
            model: 'ProductFactory',
            required: true,
            include: {
              model: 'ProductFactoryBilling'
            }
          }
        ]
      }
    })
      .then(products=>{
        this.setState({ products });
      });
  };

  updateData = newState=>{
    const { products, factories, categoryId, productId, factoryId, factoryBillingId } = { ...this.state, ...newState };
    if (categoryId !== this.state.categoryId) {
      // alterando a categoria reseta todo o formulário
      this.setState({
        categoryId,
        productId: '',
        factoryId: '',
        factoryBillingId: '',
        shippingId: 0,
        ...mapValues(this.inputs, v=>undefined)
      }, this.handleChangeCategory);
      this.inputs = {};
    } else {
      const product = find(products, {id: productId});
      const factory = find(get(product, 'ProductFactories'), { factoryId });

      if (productId !== this.state.productId) {
        // alterando o produto
        if (!product) {
          // verifica se o produto é válido
          newState.productId = '';
        }
        if (!factory) {
          // verifica se o produto tem a fábrica
          newState.factoryId = '';
          newState.shippingId = 0;
          newState.factoryBillingId = '';
        }
      } else if (factoryId !== this.state.factoryId) {
        // verifica se a fábrica tem o local de faturamento
        let productFactoryBilling;
        if (factoryBillingId) {
          const factoryBillings = flatMap(factories, 'FactoryBillings');
          const _factoryBilling = find(factoryBillings, {id: factoryBillingId});
          productFactoryBilling = find(factoryBillings, {
            billingId: get(_factoryBilling, 'billingId'),
            factoryId
          });
        }
        newState.factoryBillingId = get(productFactoryBilling, 'id') || '';
        newState.shippingId = 0;
      }
      this.setState(newState);
    }
  };

  handleSubmit = event=>{
    event.preventDefault();
    if (!this.isInvalid()) {
      this.setState({loading: true}, ()=>{
        let { order } = this.state;
        let request;

        const { history, enqueueSnackbar } = this.props;
        const body = pick(this.state, ORDER);

        if (!body.shippingId) {
          body.shippingId = null;
        }

        if (order) {
          request = asyncRequest(`/orders/${order.id}`, {
            method: 'PUT',
            body
          });
        } else {
          request = asyncRequest('/orders', {
            method: 'POST',
            body
          });
        }

        request
          .then(newOrder=>(
            asyncRequest(`/orders/${newOrder.id}/calculate`, {
              method: 'POST',
              body: {
                inputs: transform(this.inputs, (arr, { id, value })=>{
                  arr.push({ id, value });
                }, [])
              }
            })
              .then(calc=>{
                if (!order) {
                  history.push(`/calculo/${newOrder.id}`);
                } else {
                  each(this.inputs, input=>{
                    input.original = input.value;
                  });
                  order.OrderViews = get(calc, 'views');
                  Object.assign(order, newOrder);
                  this.setState({
                    loading: false
                  });
                }
              })
          ))
          .catch(xhr=>{
            this.setState({loading: false});
            if (Array.isArray(xhr)) {
              xhr = xhr[0];
            }
            enqueueSnackbar(xhr.message, { variant: 'error' });
          });
      });
    }
  };

  handleRemove = event=>{
    const { order } = this.state;
    if (get(order, 'id')) {
      const { enqueueSnackbar, history } = this.props;
      this.setState({loading: true}, ()=>{
        asyncRequest(`/orders/${order.id}/cancel`, {
          method: 'DELETE'
        })
          .then(()=>{
            enqueueSnackbar('Cálculo removido com sucesso', { variant: 'success' });
            history.replace('/calculos');
          })
          .catch(xhr=>{
            enqueueSnackbar(xhr.message, { variant: 'error' });
            this.setState({loading: false});
          });
      });
    }
  };

  handleFinish = event=>{
    const { order } = this.state;
    if (get(order, 'id')) {
      const { enqueueSnackbar, history } = this.props;
      this.setState({loading: true}, ()=>{
        asyncRequest(`/orders/${order.id}/finish`, {
          method: 'POST'
        })
          .then(()=>{
            enqueueSnackbar('Cálculo finalizado com sucesso', { variant: 'success' });
            history.replace('/calculos');
          })
          .catch(xhr=>{
            enqueueSnackbar(xhr.message, { variant: 'error' });
            this.setState({loading: false});
          });
      });
    }
  };

  isInvalid = ()=>{
    const { loading, categoryId, productId, factoryId, factoryBillingId, marketId, client, application } = this.state;
    return !!loading || !categoryId || !productId || !factoryId || !factoryBillingId || !marketId || !client || !application;
  };

  hasChanged = ()=>{
    const original = pick(this.state.order, ORDER);
    const current = pick(this.state, ORDER);
    const dataDiff = diff(current, original);
    return !!Object.keys(dataDiff).length || !!find(this.inputs, i=>i.original!==i.value);
  };

  render() {
    const { classes } = this.props;
    const {
      categoryId,
      productId,
      factoryId,
      factoryBillingId,
      shippingId,
      marketId,
      client,
      application,
      loading,
      categories,
      factories,
      products,
      markets,
      order
    } = this.state;

    const changed = this.hasChanged();
    const category = find(categories, {id: categoryId});
    const productFactories = flatMap(products, 'ProductFactories');
    const productFactory = find(productFactories, { factoryId });
    const productBillings = get(productFactory, 'ProductFactoryBillings');
    const factoryOptions = filter(factories, fa=>!!find(productFactories, {factoryId: fa.id}));
    const productOptions = filter(products, p=>!!find(p.ProductFactories, { factoryId }));
    const factory = find(factoryOptions, {id: factoryId});
    const factoryBillings = get(productBillings, 'length')
      ? filter(get(factory, 'FactoryBillings'), fb=>!!find(productBillings, { factoryBillingId: fb.id }))
      : get(factory, 'FactoryBillings');

    return (
      <Container className={classes.root}>
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Hidden xsDown>
            <Tabs
              value={categoryId||false}
              onChange={this.handleChangeTab('categoryId')}
              centered
            >
              {map(categories, option=>(
                <Tab key={option.id} label={option.name} value={option.id} disabled={loading||!categories} />
              ))}
            </Tabs>
          </Hidden>

          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Hidden smUp>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    select
                    name="categoryId"
                    value={categoryId}
                    fullWidth
                    label="Categoria"
                    disabled={loading||!categories}
                    onChange={this.handleChange}
                  >
                    {map(categories, option=>(
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Hidden>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  select
                  name="factoryId"
                  value={factoryId}
                  fullWidth
                  label="Origem"
                  disabled={!categoryId||loading}
                  onChange={this.handleChange}
                >
                  {map(factoryOptions, option=>(
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  select
                  name="factoryBillingId"
                  value={factoryBillingId}
                  fullWidth
                  disabled={!factoryId||loading}
                  label="Local de faturamento"
                  onChange={this.handleChange}
                >
                  {map(sortBy(factoryBillings, 'Billing.name'), option=>(
                    <MenuItem key={option.id} value={option.id}>
                      {get(option, 'Billing.name')}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={8} lg={6}>
                <TextField
                  select
                  name="productId"
                  value={productId}
                  fullWidth
                  disabled={!factoryId||loading||!products}
                  label="Matéria prima"
                  onChange={this.handleChange}
                >
                  {map(productOptions, option=>(
                    <MenuItem key={option.id} value={option.id}>
                      {get(option, 'ProductCategories[0].name') || option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {map(orderBy(get(category, 'Inputs'), 'Letter.letter'), input=>{
                const name = getInputName(input.id);
                const val = this.state[name]||'';
                let helper = '';
                if (input.min) {
                  helper += `mínimo ${input.min}`;
                  if (input.max) {
                    helper += ', ';
                  }
                }
                if (input.max) {
                  helper += `máximo ${input.max}`;
                }
                const props = {};
                if (input.unit) {
                  props.InputProps = {
                    endAdornment: <InputAdornment position="end">{input.unit}</InputAdornment>,
                  };
                }
                return (
                  <Grid key={input.id} item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      {...props}
                      name={name}
                      value={val}
                      fullWidth
                      label={input.name}
                      type="number"
                      helperText={helper}
                      disabled={loading}
                      onChange={this.handleChangeInput(input.id)}
                    />
                  </Grid>
                );
              })}
              
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  select
                  name="shippingId"
                  value={shippingId||0}
                  fullWidth
                  disabled={!factoryId||loading||!!get(category, 'noShipping')}
                  label="Frete"
                  onChange={this.handleChange}
                >
                  <MenuItem value={0}>FOB</MenuItem>
                  {map(sortBy(get(factory, 'Shippings'), 'name'), option=>(
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  name="client"
                  value={client}
                  fullWidth
                  label="Cliente"
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  select
                  name="marketId"
                  value={marketId}
                  fullWidth
                  label="Mercado"
                  disabled={loading||!markets}
                  onChange={this.handleChange}
                >
                  {map(markets, option=>(
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  name="application"
                  value={application}
                  fullWidth
                  label="Aplicação"
                  disabled={loading}
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>

            {!!get(order, 'OrderViews.length')&&!changed&&(
              <Fragment>
                <Divider className={classes.divider} />

                <Grid container spacing={3}>
                  {map(orderBy(order.OrderViews, 'viewId'), orderView=>(
                    <Grid key={orderView.id} item className={classes.result}>
                      <Typography variant="overline">{get(orderView, 'View.name')}</Typography>
                      <Typography variant="h6">
                        <strong>{priceFormat(orderView.value)}</strong>
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Fragment>
            )}
          </Paper>

          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={this.isInvalid()||!changed}
              size="large"
            >
              Calcular
            </Button>

            {!!order&&(
              <Fragment>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  className={classes.button}
                  disabled={loading||changed}
                  onClick={this.handleFinish}
                >
                  Finalizar
                </Button>

                <Button
                  variant="contained"
                  color="default"
                  size="large"
                  className={classes.danger}
                  disabled={loading}
                  onClick={this.handleRemove}
                >
                  Cancelar
                </Button>                
              </Fragment>
            )}

            <Button
              component={Link}
              to="/calculos"
              size="large"
              className={classes.button}
            >
              Seus cálculos
            </Button>
          </div>
        </form>
      </Container>
    );
  }
};

OrderForm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(withStyles(styles)(OrderForm));