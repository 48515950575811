import { remove, clone } from 'lodash';

export const PATH = process.env.REACT_APP_API_URL;

class NAMESPACE {
  static base = 'KnaufIsopor@';
  static get TOKEN () {
    return `${this.base}accessToken`;
  }
};

let TOKEN = localStorage.getItem(NAMESPACE.TOKEN) || sessionStorage.getItem(NAMESPACE.TOKEN);
let USER;
const EVENTS = [];

export const parseResponse = response=>{
  return new Promise((resolve, reject)=>{
    if (response.status===204) {
      return resolve();
    }
    response.json()
      .then(responseJson=>{
        if (response.status<200||response.status>=300) {
          responseJson.status = response.status;
          reject(responseJson);
        } else {
          const count = response.headers.get('X-Total-Count');
          if (count) {
            responseJson.count = parseInt(count, 10);
          }
          resolve(responseJson);
        }
      });
  });
};

export const asyncRequest = (url, options = {})=>{
  const {
    method = 'GET',
    headers,
    query,
    body,
    isPublic,
    accessToken = TOKEN
  } = options;
  
  const params = {
    method,
    headers: {
      ...headers
    },
  };

  if (!isPublic && accessToken) {
    params.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  if (body) {
    params.headers["Accept"] = "application/json";
    params.headers["Content-Type"] = "application/json";

    params.body = JSON.stringify(body);
  }
  if (query) {
    url += `?q=${JSON.stringify(query)}`;
  }

  return fetch(`${PATH}${url}`, params)
    .then(parseResponse);
};

const TRIGGER = ()=>{
  EVENTS.forEach(fn=>fn(USER));
  return USER;
};

const LOGOUT = ()=>{
  localStorage.removeItem(NAMESPACE.TOKEN);
  sessionStorage.removeItem(NAMESPACE.TOKEN);
  TOKEN = USER = null;
};

export default class Auth {
  static get currentUser () {
    return clone(USER);
  }

  static bind (func) {
    if (typeof func === 'function') {
      EVENTS.push(func);
      return ()=>remove(EVENTS, func);
    }
  }

  static auth (credentials, remember) {
    return asyncRequest('/auth', {
      method: 'POST',
      body: credentials
    })
      .then(({ access_token: accessToken })=>(
        this.login(accessToken)
          .then(user=>{
            if (remember) {
              localStorage.setItem(NAMESPACE.TOKEN, accessToken);
            } else {
              sessionStorage.setItem(NAMESPACE.TOKEN, accessToken);
            }
          })
      ))
  }

  static login (accessToken = TOKEN) {
    if (!accessToken) {
      return Promise.reject("usuário não logado");
    }
    return asyncRequest('/auth/me', { accessToken })
      .then(user=>{
        TOKEN = accessToken;
        USER = user;
        return TRIGGER();
      });
  }

  static resetPassword (email) {
    if (!email) {
      return Promise.reject("parâmetro `email:string` obrigatório");
    }
    return asyncRequest('/auth/passwordRecovery', {
      method: "POST",
      body: {
        email,
        redirectUrl: `${window.location.origin}/alterar_senha`
      }
    });
  }

  static changePassword (password, accessToken) {
    if (!password) {
      return Promise.reject("parâmetro `password:string` obrigatório");
    }
    if (!accessToken) {
      return Promise.reject("parâmetro `token:string` obrigatório");
    }
    return asyncRequest('/auth/changePassword', {
      method: "POST",
      body: { password },
      accessToken
    });
  }

  static logout () {
    return asyncRequest('/auth', {
      method: 'DELETE',
    })
      .then(()=>{
        LOGOUT();
        return TRIGGER();
      });
  }
};